<template>
  <tr class="heighRow">
    <td>
      <div class="d-flex justify-content-center">
        <span v-text="item.count" class="text-right"></span>
      </div>
    </td>
    <td class="textWidth" style="padding: 0 20px 0 20px">
      <input
        style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
        v-if="edit"
        v-model="itemChoice.name"
        placeholder="Tên nhóm thuộc tính"
        class="w-100 h-90 input"
      />
      <span class="font-weight-bolder" v-else>{{ item.name }}</span>
    </td>

    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon class="mr-2 text-primary" small @click="updateItem()">mdi-check</v-icon>
          <v-icon class="mr-2 text-danger" small @click="cancelEdit()">mdi-close</v-icon>
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="mr-2 text-primary"
            @click="editItem()"
            v-show="checkPermission('ORDER_SOURCE_UPDATE')"
            v-b-tooltip
            style="font-size: 18px"
            title="Cập nhật"
          >mdi-pencil</v-icon>
          <v-icon
            small
            class="mr-2 text-danger"
            @click="showDeleteAlert"
            v-show="checkPermission('ORDER_SOURCE_DELETE')"
            v-b-tooltip
            style="font-size: 18px"
            title="Xóa"
          >mdi-delete</v-icon>
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
.textWidth {
  width: 70%;
}
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['item', 'listFeature'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: '',
        name: ''
      }
    };
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.name = this.item.name;
    },
    cancelEdit: function() {
      this.edit = false;
      if (this.item.name == '') {
        this.$emit('cancelAdd');
      }
      this.itemChoice.id = '';
      this.itemChoice.name = '';
    },
    updateItem: function() {
      this.edit = false;
      if (this.item.name == '') {
        this.item.name = this.itemChoice.name;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice.name = '';
    },
    showDeleteAlert: function() {
      Swal.fire({
        title: 'Xóa nhóm thuộc tính!',
        text: 'Bạn có chắc muốn xóa nhóm thuộc tính này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    checkAddRow() {
      if (this.item.name == '') {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    }
  }
};
</script>
