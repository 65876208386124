<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách thuộc tính'">
          <template v-slot:toolbar>
            <b-row>
              <b-col>
                <button
                  v-bind:style="btnCreate"
                  style="width: 191px"
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="showModal"
                >
                  Danh sách nhóm thuộc tính
                </button>
                <b-modal
                  ref="my-modal"
                  hide-footer
                  title="Danh sách nhóm thuộc tính"
                >
                  <div class="d-flex justify-content-end mb-4">
                    <button
                      v-bind:style="btnCreate"
                      class="btn btn-primary font-weight-bolder btn-sm"
                      @click="addRow"
                    >
                      <i style="font-size: 1rem" class="flaticon2-add-1"></i
                      >Thêm mới
                    </button>
                  </div>
                  <table
                    class="table table-bordered table-vertical-center table-hover"
                  >
                    <thead>
                      <tr>
                        <th style="textalign: center; color: rgb(24, 28, 50)">
                          STT
                        </th>
                        <th scope="col">Tên</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody
                      v-for="(item, index) in listProductPropertyGroup"
                      :key="index"
                    >
                      <ProductPropertyGroup
                        v-bind:item="item"
                        v-on:updateItem="updateGroup"
                        v-bind:listFeature="listFeature"
                        v-on:deleteItem="deleteGroup"
                        v-on:addItem="addNewGroup"
                        v-on:cancelAdd="cancelAdd"
                      />
                    </tbody>
                  </table>
                </b-modal>
              </b-col>
              <b-col>
                <div class="text-right">
                  <router-link
                    to="/properties/add-property"
                    v-show="checkPermission('PROPERTY_INSERT')"
                  >
                    <button
                      v-bind:style="btnCreate"
                      class="btn btn-primary btn-sm"
                    >
                      <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo
                      mới
                    </button>
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-4"></div>
            <div class="col-md-12">
              <div class="col-md-6">
                <b-table
                  :items="items"
                  :fields="fields"
                  class="table-bordered table-hover col-md-12"
                  :busy="onLoading"
                >
                  <template v-slot:table-busy>
                    <vcl-table
                      :speed="5"
                      :animate="true"
                      :columns="10"
                    ></vcl-table>
                  </template>
                  <template v-slot:cell(stt)="row">
                    <div>
                      <span
                        v-text="row.item.stt"
                        class="d-flex justify-content-center"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(name)="row">
                    <div class="font-weight-bolder">
                      <span v-text="row.item.name" class="text-right"></span>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div class="d-flex justify-content-center">
                      <b-dropdown size="sm" id="dropdown-left" no-caret right>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item
                          @click="editItem(row.item)"
                          v-if="checkViewOnly()"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 10px"
                              class="flaticon2-box-1"
                            ></i>
                            &nbsp; Chi tiết
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="editItem(row.item)"
                          v-if="!checkViewOnly()"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-pen"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showDeleteAlert(row.item)"
                          v-show="checkPermission('PROPERTY_DELETE')"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem; color: #d33"
                              class="flaticon2-rubbish-bin-delete-button"
                            ></i>
                            &nbsp; Xóa
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="col-md-12 d-flex">
              <div class="col-md-3">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số thuộc tính:
                  {{ totalRow }}
                </p>
              </div>
              <div class="d-flex col-md-3 justify-content-end">
                <b-pagination-nav
                  :link-gen="linkGen"
                  v-show="numberOfPages >= 2"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </div>
              <b-col></b-col>
              <b-col></b-col>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.sttClass {
  width: 12%;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';
import ProductPropertyGroup from '../../components/ProductPropertyGroup.vue';
import { VclTable } from 'vue-content-loading';

export default {
  data() {
    return {
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important'
      },
      search: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          tdClass: 'sttClass',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '12%',
            textAlign: 'center'
          }
        },
        {
          key: 'name',
          label: 'Tên thuộc tính',
          sortable: false,
          thStyle: { color: '#181c32', width: '240px' }
        },
        {
          key: 'propertyGroup',
          label: 'Nhóm thuộc tính',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '150px' }
        },
        { key: 'actions', label: '' }
      ],
      items: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      itemChoice: null,
      totalRow: '',
      listFeature: [],
      listProductPropertyGroup: [
        {
          id: 1,
          name: 'AAA'
        },
        {
          id: 2,
          name: 'BBB'
        },
        {
          id: 3,
          name: 'CCC'
        }
      ],
      count: 1
    };
  },
  components: {
    KTCodePreview,
    ProductPropertyGroup,
    VclTable
  },
  created() {
    this.fetchData();
    this.getListFeature();
    this.getListPropertyGroup();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thuộc tính', route: '/properties' },
      { title: 'Danh sách thuộc tính' }
    ]);
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    fetchData: async function() {
      this.onLoading = true;
      this.items = [];
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let param = {
        page: this.page,
        limit: 10
      };
      let paramQuery = {
        params: param
      };
      ApiService.setHeader();
      ApiService.query('property', paramQuery).then(({ data }) => {
        if (this.page === 1) {
          this.count = 1;
        } else {
          this.count = 1 + (this.page - 1) * 10;
        }
        this.numberOfPage = data.data.total_page;
        this.totalRow = data.data.total_row;
        data.data.list_property.forEach(element => {
          let item = {
            stt: this.count,
            id: element.id,
            name: element.name,
            propertyGroup: element.propertyGoup
          };
          this.items.push(item);
          this.count++;
        });
        this.onLoading = false;
      });
    },
    editItem: function(item) {
      this.$router.push({
        path: 'properties/update-property',
        query: { id: item.id }
      });
    },
    deleteItem: async function(item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`property/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa thuộc tính!',
        text: 'Bạn có chắc muốn xóa thuộc tính này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function() {
      let count = 0;
      if (localData.checkPermission('PROPERTY_VIEW')) {
        count++;
      }
      if (localData.checkPermission('PROPERTY_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    getListFeature() {
      this.listFeature = localData.getData('listFeature');
    },
    addRow() {
      let data = {
        count: '',
        id: '',
        name: ''
      };
      this.listProductPropertyGroup.unshift(data);
    },
    cancelAdd() {
      this.listProductPropertyGroup.shift();
      this.getListPropertyGroup();
    },
    addNewGroup(item) {
      let data = {
        name: item.name
      };
      ApiService.setHeader();
      ApiService.post('propertyGroup/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.getListPropertyGroup();
            this.makeToastSuccess(data.message);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.message);
        });
    },
    updateGroup(item) {
      let data = {
        id: item.id,
        name: item.name
      };
      ApiService.setHeader();
      ApiService.post(`propertyGroup/update`, data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.getListPropertyGroup();
            this.makeToastSuccess(data.message);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.message);
        });
    },
    deleteGroup(id) {
      ApiService.setHeader();
      ApiService.delete(`propertyGroup/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.getListPropertyGroup();
            this.makeToastSuccess(data.message);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.message);
        });
    },
    getListPropertyGroup: async function() {
      this.listProductPropertyGroup = [];
      let param = {
        page: 1,
        limit: 10,
        name: ''
      };
      let paramQuery = {
        params: param
      };
      ApiService.setHeader();
      ApiService.query('propertyGroup', paramQuery).then(({ data }) => {
        let count = 1;
        this.numberOfPage = data.data.total_page;
        data.data.list_property_group.forEach(element => {
          let item = {
            count: count,
            id: element.id,
            name: element.name
          };
          this.listProductPropertyGroup.push(item);
          count++;
        });
      });
    }
  }
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>
